
.app-container {
  display: flex;
}

.main-content {
  display: flex;
  flex: 1;
}

.sidebar {
  margin-right: 50px;
}

.table-container {
  flex: 1;
  padding: 20px;
  overflow: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  margin: 20px; 
  font-family: Avenir, Montserrat, Corbel, 'URW Gothic', source-sans-pro, sans-serif;
  font-weight: normal;
}

table {
  width: 100%;
  border-collapse: collapse;
  border: 0px solid #ddd;
}

th, td {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px;
  text-align: left;
  color: rgba(0, 0, 0, 0.5);
}

th {
  background-color: white;
  border-bottom: 1px solid #ddd;
}

tr:hover {
  background-color: #f5f5f5;
}

.centered {
  text-align: center;
}

.week-ending-header {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.sort-option {
  display: inline-block;
  vertical-align: middle;
}

